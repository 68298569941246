<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} API</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-6">
                    <ValidationProvider
                      name="Name"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <label>Name *</label>
                      <input
                        type="text"
                        placeholder="Name"
                        v-model="api.name"
                        class="form-control"
                        :class="classes"
                        readonly="true"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  
                  <div class="form-group col-md-6">
                    <label>Description *</label>
                    <ValidationProvider
                      name="Description"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="Description"
                        v-model="api.description"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Type *</label>
                    <ValidationProvider
                      name="Type"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control apiTypeClass"
                        v-model="api.type"
                        :class="classes"
                      >
                        <option value="" selected>
                          --------- Select ---------
                        </option>
                        <option
                          v-for="(type, key) in apiType"
                          :value="type.id"
                          v-bind:key="key"
                        >
                          {{ type.text }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Method *</label>
                    <ValidationProvider
                      name="Method"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control apiTypeClass"
                        v-model="api.method"
                        :class="classes"
                      >
                        <option value="" selected>
                          --------- Select ---------
                        </option>
                        <option
                          v-for="(type, key) in apiMethod"
                          :value="type.id"
                          v-bind:key="key"
                        >
                          {{ type.text }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Service Provider *</label>
                    <ValidationProvider
                      name="Service Provider"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control apiTypeClass"
                        v-model="api.serviceProvider"
                        :class="classes"
                      >
                        <option value="" selected>
                          --------- Select ---------
                        </option>
                        <option
                          v-for="(type, key) in serviceProvider"
                          :value="type.id"
                          v-bind:key="key"
                        >
                          {{ type.text }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Endpoint *</label>
                    <ValidationProvider
                      name="Endpoint"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="Endpoint"
                        v-model="api.endpoint"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-6">
                    <label>API Product *</label>
                    <select
                      data-placeholder="--------- Select ---------"
                      id="apiProduct"
                      class="form-control chosen-select"
                      multiple
                      tabindex="4"
                    ></select>
                  </div>

                  <div class="form-group col-md-6">
                    <label>API Group *</label>
                    <ValidationProvider
                      name="GroupId"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                    <select
                      class="form-control apiTypeClass"
                      v-model="api.groupId"
                      :class="classes"
                    >
                      <option value="" selected>
                        --------- Select ---------
                      </option>
                      <option
                        v-for="(type, key) in apiGroup"
                        :value="type.id"
                        v-bind:key="key"
                      >
                        {{ type.text }}
                      </option>
                    </select>
                     <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                  </div>

                <div class="form-group col-md-6">
                    <ValidationProvider
                      name="API Scope"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <label>API Scope *</label>
                      <input
                        type="text"
                        placeholder="Name"
                        v-model="api.apiScope"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-6">
                    <ValidationProvider
                      name="API Description"
                      :rules="{ required: false }"
                      v-slot="{ errors, classes }"
                    >
                      <label>Scope Description *</label>
                      <input
                        type="text"
                        placeholder="API Description"
                        v-model="api.apiScopeDescription"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Show on public portal</label>
                    <div class="switch">
                      <div class="onoffswitch">
                        <input
                          type="checkbox"
                          class="onoffswitch-checkbox form-control"
                          v-model="api.accessFlag"
                          id="publicPortal"
                        />
                        <label class="onoffswitch-label" for="publicPortal">
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label>API version</label>
                    <select
                      class="form-control apiTypeClass" v-model="api.version"
                    >
                      <option value="" selected>
                        --------- Select ---------
                      </option>
                      <option v-for="(item, index) in apiVersions" 
                        :value="item.value" 
                        v-bind:key="index" 
                        >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>

                </div>
                <div class="col-md-14">
                  <codemirror
                    v-model="api.definitionFile"
                    :options="cmOptions"
                  />
                </div>
                <div class="hr-line-dashed"></div>
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-secondart btn-sm mr-1" @click="back()">Back</button>
                    <button class="btn btn-white btn-sm" type="button">
                      Clear
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn btn-primary btn-sm ladda-button"
                      data-style="zoom-in"
                      type="submit"
                      :disabled="invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import Utils from "../plugin/Utils.js";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      api: {
        name: "",
        description: "",
        type: "",
        method: "",
        definitionFile: "",
        serviceProvider: "",
        endpoint: "",
        id: 0,
        groupId: 0,
        apiProduct: "",
        accessFlag: false,
        version: ""
      },
      title: Constant.data.ADD_TITLE,
      apiType: [],
      apiMethod: [],
      serviceProvider: [],
      apiGroup: {},
      apiProductList: {},
      cmOptions: {
        tabSize: 4,
        mode: "text/javascript",
        theme: "default",
        lineNumbers: true,
        line: true,
      },
      apiVersions: [
        { title: 'Version 1',
          value: 'v1' },
        { title: 'Version 2',
          value: 'v2' }
      ]
    };
  },
  created: function () {
    this.loadApiData();
  },
  mounted: function () {
    this.getApiDefinitionType();
  },
  methods: {
    onSubmit: function () {
      const self = this;
      this.api.apiProduct = $("#apiProduct").chosen().val().join();
      if(Utils.isEmptyStr(this.api.apiProduct)) {
        toastr.error('Please select at least one API Product', "Error");
      }else {
        AxiosService.post(Constant.url.API_UPDATE, this.api).then((result) => {
          if (
            Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
            result.responseCode == Constant.data.SUCCESS
          ) {
            AuditListner.addAuditLog(
              AuditListner.initalizedData(
                Constant.data.API_DEFINITION_ACTIVITY,
                Constant.data.UPDATE_TITLE,
                AuditListner.apiDefinitionTemplate(self.api)
              )
            );
            self.$router.replace(Constant.url.SEPARATER + Constant.url.API_URL);
          }
        });
      }
    },
    loadApiData: function () {
      var apiId = this.$route.query.id;
      const self = this;
      if (apiId != undefined) {
        AxiosService.get(Constant.url.API_GET + apiId).then((result) => {
          self.api = result;
          self.api.version = self.api.version ? self.api.version : '';
        });
        this.title = Constant.data.UPDATE_TITLE;
      }
    },
    getApiDefinitionType: async function () {
      let self = this;
      AxiosService.get(Constant.url.API_DEFINITION_TYPE_KEY_VALUE).then(
        (result) => {
          this.apiType = result;
        }
      );
      AxiosService.get(Constant.url.API_DEFINITION_METHOD_KEY_VALUE).then(
        (result) => {
          this.apiMethod = result;
        }
      );
      AxiosService.get(
        Constant.url.API_DEFINITION_SERVICE_PROVIDER_KEY_VALUE
      ).then((result) => {
        this.serviceProvider = result;
      });
      AxiosService.get(Constant.url.PRODUCT_KEYVALUE_URI).then( async (result) => {
         this.apiProductList = result;
         $.each(this.apiProductList, function (key, value) {
          $("#apiProduct").append(
            '<option value="' +
              value.id +
              '">' +
              value.description +
              "</option>"
          );
        });
        await $("#apiProduct").trigger("chosen:updated");
        await $(".chosen-select").chosen({ width: "100%" });
        await setTimeout(() => {
           if(self.api.hasOwnProperty('apiProduct')) {
              $("#apiProduct").val(self.api.apiProduct.split(',')).trigger('chosen:updated');
            }
        }, 1000);
      });
      AxiosService.get(Constant.url.API_GROUP_KEYVALUE_URI).then(result => {
         this.apiGroup = result;
      });
    },
  },
};
</script>
<style scoped></style>
